import socketService from '../socket/socketService'
import store from '../../../store/index'

class ChatSocketService {
  store = store

  // jwtConfig <= Will be used by this service
  constructor() {
    this.socketService = socketService
  }

  connectToChatUpdates(callback) {
    const id = this.store.getters['user-auth/getId']
    this.socketService.connect()
    setTimeout(() => {
      this.socketService.subscribe(`/topic/public/${id}`, callback)
    }, 2000)
  }

  //   chatMessage = {
  //     owner: '662bc492a6e49911e1c8e598', // id del usuario loggeado
  //     message: messageInput.value,
  //     channel: '662bdd7f2155382701d56b93', // id del chat compartido
  //     chatType: 'CHAT',
  //   }
  sendMessage(idOtherUser, chatMessage) {
    this.socketService.send(`/app/chat.send/${idOtherUser}`, chatMessage)
  }

  //   checkinObj = {
  //     owner: '662bc492a6e49911e1c8e598', // id del usuario loggeado
  //     message: 'Joined Chat',
  //     channel: '662bdd7f2155382701d56b93', // id del chat compartido
  //     chatType: 'JOIN',
  //   }
  sendCheckin(idOtherUser, checkinObj) {
    this.socketService.send(`/app/chat.register/${idOtherUser}`, checkinObj)
  }

  //   checkoutObj = {
  //     owner: '662bc492a6e49911e1c8e598', // id del usuario loggeado
  //     message: 'Exited Chat',
  //     channel: '662bdd7f2155382701d56b93', // id del chat compartido
  //     chatType: 'LEAVE',
  //   }
  sendCheckout(idOtherUser, checkoutObj) {
    this.socketService.send(`/app/chat.leave/${idOtherUser}`, checkoutObj)
  }
}

const chatSocketService = new ChatSocketService()
export default chatSocketService
