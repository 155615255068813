<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': (msgGrp.senderId || msgGrp.sender) === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : ''"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.index"
          class="chat-content"
        >
          <template v-if="msgData.type === 'FILE'">
            <div class="file-message">
              <feather-icon
                icon="FileIcon"
                size="25"
              />
              <span class="filename ml-1">{{ decodeURIComponent(msgData.msg.split('/').pop()) }}</span>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="sm"
                class="btn-icon rounded-circle ml-1"
                :class="(msgGrp.senderId || msgGrp.sender) === formattedChatData.contact.id ? '' : 'border-white text-white'"
                @click="getFileMessage(msgData.msg, decodeURIComponent(msgData.msg.split('/').pop()))"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                />
              </b-button>
            </div>
          </template>
          <template v-else>
            <p>{{ msgData.msg }}</p>
            <small class="text-muted font-small-1">{{ msgData.time2 }}</small>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { BAvatar, BButton } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            time2: msg.time2,
            index,
            type: msg.type,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
              time2: msg.time2,
              index,
              type: msg.type,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    const getFileMessage = (url, filename) => {
      store.dispatch('app-chat/getFileMessage', url)
        .then(response => {
          const downloadedFile = response.data
          const downloadLink = document.createElement('a')
          downloadLink.href = URL.createObjectURL(downloadedFile)
          downloadLink.download = filename
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
          URL.revokeObjectURL(downloadLink.href)
        })
    }
    return {
      formattedChatData,
      getFileMessage,
    }
  },
}
</script>

<style scoped>
.border-white {
  border: 1px solid #fff !important;
}
</style>
