export default {
  // Base url path
  baseUrl: 'https://api.upskillassistant.com/api',
  // baseUrl: 'http://localhost:3000/api',
  // baseUrl: 'http://192.168.150.62:5000/nodeapp',
  // baseUrl: 'http://192.168.150.18:3000/nodeapp', // Andrei Maquina
  // baseUrl: 'http://localhost:3000/nodeapp', // Sebastian Maquina
  // baseUrl: 'http://192.168.84.244:3000/api', // Sebastian Maquina
  // baseUrl: '', // Sebastian Maquina
  // baseUrl: 'https://appanalitica.zapatoskarsten.com.mx/mkt', // Sebastian Maquina

  // Endpoints
  loginEndpoint: '/auth/login',
  verifyAuthyCodeEndpoint: '/api/v1/auth/verify',
  resetPassword: '/auth/forgot-password',
  recoveryPassword: '/auth/recover-password',
  forgotPassword: '/auth/forgot-password',

  getUsers: '/user/get-system-users',
  getUser: '/user/get-system-user',
  deleteUser: '/user/delete-system-user',
  resetPasswordAdmin: '/user/update-user-password',
  registerUser: '/user/',
  updateUser: '/user/update-user',
  assignUser: '/user/assign-agent',

  getKanban: '/kanban',
  createKanban: '/kanban',
  getAllKanban: '/kanban/',
  getAllKanbanForCalendar: '/kanban/tasks-calendar',
  addTaskKanban: '/kanban/tasks',
  updateTask: '/kanban/tasks',
  deleteTask: '/kanban/tasks/delete',

  getChats: '/chatts/getChats',
  getChat: '/chatts/getHistory',
  uploadFile: '/chatts/upload-file',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
