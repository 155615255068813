import axiosIns from '@axios'
import chatService from '@core/services/chat/chatService'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        chatService
          .getChats()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { chatId }) {
      return new Promise((resolve, reject) => {
        chatService
          .getChat(chatId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendFileMessage(ctx, dataMessage) {
      return new Promise((resolve, reject) => {
        chatService
          .uploadFile(dataMessage)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFileMessage(ctx, url) {
      return new Promise((resolve, reject) => {
        chatService
          .getFile(url)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
