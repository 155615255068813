import { ref } from '@vue/composition-api'
import ChatSocketService from '@core/services/chat/chatSocketService'

export default function useChat() {
  const chatMessages = ref([])
  const newMessage = ref('')

  const sendNewMessage = (contactId, message) => {
    ChatSocketService.sendMessage(contactId, message)
  }

  const sendNewCheckin = (contactId, message) => {
    ChatSocketService.sendCheckin(contactId, message)
  }

  const sendNewCheckout = (contactId, message) => {
    ChatSocketService.sendCheckout(contactId, message)
  }

  const resolveAvatarBadgeVariant = status => {
    if (status === 'online') return 'success'
    if (status === 'busy') return 'danger'
    if (status === 'away') return 'warning'
    return 'secondary'
  }

  return {
    resolveAvatarBadgeVariant,
    chatMessages,
    newMessage,
    sendNewMessage,
    sendNewCheckin,
    sendNewCheckout,
  }
}
