import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ChatService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  getChats() {
    return axiosService.axiosIns.get(this.jwtConfig.getChats)
  }

  getChat(chatId) {
    return axiosService.axiosIns.get(`${this.jwtConfig.getChat}/${chatId}`)
  }

  uploadFile({ contactId, formData }) {
    return axiosService.axiosIns.post(`${this.jwtConfig.uploadFile}/${contactId}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  }

  /* eslint-disable class-methods-use-this */
  getFile(url) {
    return axiosService.axiosIns.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      responseType: 'blob',
    })
  }
  /* eslint-enable class-methods-use-this */
}

function useChatService() {
  const chatService = new ChatService()

  return {
    chatService,
  }
}

const { chatService } = useChatService()
export default chatService
