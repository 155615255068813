import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import store from '../../../store/index'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

const SOCKET_URL = 'https://api.upskillassistant.com/chat-socket' // Your WebSocket server URL
// const SOCKET_URL = 'http://localhost:3000/chat-socket' // Your WebSocket server URL

class ServiceSocket {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  store = store

  constructor() {
    this.stompClient = null
  }

  getToken() {
    return this.store.getters['user-auth/getToken']
  }

  connect() {
    const socket = new SockJS(SOCKET_URL)
    this.stompClient = Stomp.over(socket)
    const accessToken = this.getToken()
    this.stompClient.connect({ Authorization: `${this.jwtConfig.tokenType} ${accessToken}` }, () => {
      console.log('Connected to WebSocket')
    }, error => { console.warn(error) })
  }

  disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect()
      console.log('Disconnected from WebSocket')
    }
  }

  subscribe(destination, callback) {
    if (this.stompClient !== null) {
      this.stompClient.subscribe(destination, message => {
        callback(JSON.parse(message.body))
      })
    }
  }

  send(destination, content) {
    if (this.stompClient !== null && this.stompClient.connected) {
      this.stompClient.send(destination, JSON.stringify(content), {})
    } else {
      console.error('WebSocket not connected')
    }
  }
}

const serviceSocket = new ServiceSocket()
export default serviceSocket
