// import { isToday } from './utils'
import moment from 'moment'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarTextFromItemUser = value => {
  if (!value) return ''
  const fullname = `${value.lastname} ${value.firstname}`
  const nameArray = fullname.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  if (!value) return ''
  try {
    const dateObj = new Date(value)
    const momentObj = moment(dateObj)

    let formatString

    if (toTimeForCurrentDay && momentObj.isSame(moment(), 'day')) {
      formatString = 'h:mm A' // Use 'h:mm a' for lowercase AM/PM
    } else {
      formatString = 'M/D'
    }

    return momentObj.format(formatString)
  } catch (error) {
    console.error('Error parsing date:', error)
    return ''
  }
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const unixTimestampToDate = unixTimestamp => (new Date(unixTimestamp * 1000)).toISOString().slice(0, 10)

// Function to convert date string 'YYYY-MM-DD' to Unix timestamp
export const dateToUnixTimestamp = dateString => (new Date(dateString)).getTime() / 1000

export const prettierFormatUnixTimestamp = unixTimestamp => {
  const date = new Date(unixTimestamp * 1000) // Convert Unix timestamp to milliseconds
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const dayOfWeek = daysOfWeek[date.getDay()]
  const month = monthsOfYear[date.getMonth()]
  const day = String(date.getDate()).padStart(2, '0')
  const year = date.getFullYear()

  return `${dayOfWeek} ${month} ${day} ${year}`
}
